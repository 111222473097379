if (setCookieDomain == undefined) {
    function setCookieDomain(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        var domain =
            "." +
            location.hostname.substring(
                location.hostname.indexOf("dafiti"),
                location.hostname.length
            );
        document.cookie =
            name + "=" + (value || "") + expires + "; path=/; domain=" + domain;
    }
}
if (callGaEvent == undefined) {
    function callGaEvent(status) {
        var dataLayer = dataLayer || [];

        dataLayer.push({
            event: "ab_test_event_ga4",
            ab_test_name: "new_login_front",
            ab_test_variant: status,
        });
    }
}

configCatReady.then(function () {
    try {
        configCatClient
            .getValueAsync("hashloginfront", false, userObjectUuid)
            .then(function (value) {
                if (value) {
                    var isNewLogin = getCookie("new_login_front");
                    if (isNewLogin == undefined) {
                        configCatClient
                            .getValueAsync(
                                "rolloutloginfront",
                                false,
                                userObjectUuid
                            )
                            .then(function (value) {
                                if (value) {
                                    setCookieDomain(
                                        "new_login_front",
                                        "ligado",
                                        3
                                    );
                                } else {
                                    setCookieDomain(
                                        "new_login_front",
                                        "desligado",
                                        3
                                    );
                                }
                            });
                    }
                } else {
                    setCookieDomain("new_login_front", "", -1);
                }
            });

            configCatClient
            .getValueAsync("togglecart", false, userObjectUuid)
            .then(function (value) {
                if (value) {
                    var isNewCart = getCookie("new_cart_web");
                    if (isNewCart == undefined) {
                        configCatClient
                            .getValueAsync(
                                "rolloutnewcart",
                                false,
                                userObjectUuid
                            )
                            .then(function (value) {
                                if (value) {
                                    setCookieDomain(
                                        "new_cart_web",
                                        "ligado",
                                        3
                                    );
                                } else {
                                    setCookieDomain(
                                        "new_cart_web",
                                        "desligado",
                                        3
                                    );
                                }
                            });
                    }
                } else {
                    setCookieDomain("new_cart_web", "", -1);
                }
            });

        configCatClient
            .getValueAsync("xconfig", false, userObject)
            .then(function (value) {
                if (value) {
                    setCookieDomain("x-config", true, 1);
                } else {
                    setCookieDomain("x-config", false, -1);
                }
            });

        configCatClient
            .getValueAsync("installmentsitem", false, userObject)
            .then(function (value) {
                if (value) {
                    setCookieDomain("carrinho_alterado_PCJ", true, 1);
                } else {
                    setCookieDomain("carrinho_alterado_PCJ", false, -1);
                }
            });
    } catch (e) {
        console.error("An error has occur", e);
    }
});
